@import '../theme/palette.module';

.head {
   position       : fixed;
   width          : 100%;
   height         : auto !important;
   top            : 0;
   display        : flex;
   justify-content: space-between;
   align-items    : center;
   line-height    : 1;
   background     : $bgMain;
   box-shadow     : 0px 3px 6px -3px $shadow;

   color  : $secondary;
   z-index: 999;

   .btmMenu {
      color     : $primary;
      background: transparent;
      font-size : 21px;
   }

   .rightBox {
      a {
         color       : $secondary !important;
         font-size   : 16px;
         margin-right: 16px;

         &:hover {
            color: $secHover !important
         }
      }

      .btnExit {
         color    : $red;
         font-size: 20px;
      }
   }
}

.draw {
   max-width: 100px;
}

.ant-drawer-body {
   background: $bgMain;
}

.menu {
   padding     : 0;
   background  : transparent;
   border-right: none;

   .menuItem {
      background   : transparent;
      color        : $primary;
      font-size    : 18px;
      padding      : 0 10px 0 15px;
      margin-bottom: 0 !important;

      &:hover {
         color     : $primary;
         background: #9c9c9c94;
      }
   }
}

.ant-menu-item {
   margin: 0 !important;
}

.ant-menu-item-selected {
   background-color: #7b7b7b94 !important;

   &:hover {
      color     : $primary;
      background: #9c9c9c94 !important;
   }
}

.ant-drawer-content-wrapper {
   min-width: 260px;
   max-width: 350px;
}
