@import 'smart-grid';
@import '../theme/palette.module';

.items {
   margin-top: 20px;
   @include row-flex();
   justify-content: space-around;

   .item {
      @include col();
      @include size(4);
      text-align   : center;
      cursor       : pointer;
      margin-bottom: 20px;

      &:hover {
         .icon {
            color: $primHover;
         }

         .title {
            color: $primHover;
         }
      }

      .icon {
         color: $primary;
      }

      .title {
         color: $text;

         @include xs-block {
            font-size: 0.98em;
         }
      }

      sup {
         min-width    : 24px;
         height       : 24px;
         font-size    : 12px;
         line-height  : 24px;
         text-align   : center;
         background   : #ff16c3;
         border-radius: 20px;
         padding      : 0 4px;
      }
   }
}
