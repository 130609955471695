@import 'smart-grid';
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
@import '../theme/palette.module';

html {
   @include reset();
}

body {
   font-family: 'Rubik', sans-serif !important;
   background : $bgBody !important;
   color      : $text !important;
   font-size  : 14px;
   line-height: 1.42 !important;
}

.container {
   @include container();
}

.main {
   padding-top   : 60px;
   padding-bottom: 20px;
}

.error {
   margin    : 10px 0;
   color     : $red;
   font-size : 14px;
   text-align: center;
}

h1 {
   font-size    : 2em;
   font-weight  : 600 !important;
   margin-top   : 10px !important;
   margin-bottom: 20px !important;
   color        : $text !important;
}

h2 {
   font-size    : 1.8em;
   font-weight  : 600 !important;
   margin-top   : 7px !important;
   margin-bottom: 15px !important;
   color        : $text !important;
}

h3 {
   font-size    : 1.5em;
   font-weight  : 500 !important;
   margin-top   : 5px !important;
   margin-bottom: 10px !important;
   color        : $text !important;
}

h4 {
   font-size    : 1.2em;
   font-weight  : 400 !important;
   margin-top   : 3px !important;
   margin-bottom: 5px !important;
   color        : $text;
}

.t-center {
   text-align: center;
}

.my-1 {
   margin: 10px 0;
}

.my-2 {
   margin: 20px 0;
}

.mx-auto {
   margin: 0 auto;
}

.mt-1 {
   margin-top: 10px;
}

.mt-2 {
   margin-top: 20px;
}

.ml-1 {
   margin-left: 5px;
}

.ml-2 {
   margin-left: 10px;
}

.ml-3 {
   margin-left: 15px;
}

.mr-1 {
   margin-right: 5px;
}

.mr-2 {
   margin-right: 10px;
}

.mr-3 {
   margin-right: 15px;
}

.p-1 {
   padding: 10px;
}

.pt-1 {
   padding-top: 10px;
}

.pt-2 {
   padding-top: 20px;
}

.pt-3 {
   padding-top: 30px;
}

a {
   color: $primary;

   &:hover {
      color: $prim;
   }
}
