@import 'smart-grid';
@import '../theme/palette.module';

.wrap {
   display: flex;
   justify-content: space-between;
   max-width: 750px;
   margin: 0 auto;
   padding-top: 24px;
   @include md(flex-direction, column);
}

.item {
   max-width: 900px;
   padding: 3px 16px;
   margin:0 auto 16px;
   border-radius: 10px;
   background: $bgMain;

   .title {
      color: $secondary;
      font-size: 1.3em;
      font-weight: 500;

      @include sm-block() {
         font-size: 1.05em;
      }
   }
}

.details {
   margin-bottom: 24px;

   .label {
      font-size: 1.3em;
      font-weight: 500;
      color: $secondary;

      span {
         color: #fff;
      }
   }
}

.actions {
   margin-bottom: 12px;

   button {
      margin-right: 16px;
      margin-bottom: 12px;
   }

   .deleteUser {
      background: #ff4e35 !important;
   }
}

.form {
   padding: 10px;

   .row {
      display: flex;
      align-items: center;
      padding: 6px 0;

      .role {
         min-width: 100px;
      }
   }
}
