@import 'smart-grid';
@import '../theme/palette.module';

.content {
   max-width: 900px;
   margin   : 0 auto;
}

.checkBox {
   color      : $primary;
   float      : right;
   margin     : 0 10px 8px 0;
   font-size  : 18px;
   font-weight: 500;
}

.ant-checkbox-inner {
   width : 20px !important;
   height: 20px !important;
}

.note {
   padding      : 7px 12px;
   margin-bottom: 15px;
   border-radius: 10px;
   box-shadow   : 0 0 4px 1px #fd17fffc;
   background   : #fd17ff24;
   border       : 1px solid #fd17ff4f;

   .head {
      display        : flex;
      justify-content: space-between;

      .title {
         color      : $secondary;
         font-size  : 1.3em;
         font-weight: 500;

         @include sm-block() {
            font-size: 1.05em;
         }
      }

      .btnBox {
         display        : flex;
         justify-content: space-between;
         min-width      : 50px;
         align-items    : center;

         .deleteBtn {
            color    : $red;
            font-size: 18px;
            cursor   : pointer;

            &:hover {
               color: $redHover;
            }
         }

         .changeBtn {
            cursor   : pointer;
            color    : #ffd735;
            font-size: 18px;

            &:hover {
               color: #ffb500;
            }
         }
      }


   }

   .content {
      margin-top: 5px;
      font-size : 1.2em;
   }
}

.noteFormBox {
   width     : 100%;
   max-width : 900px;
   margin    : 5px auto 16px;
   background: $bgMain;

   .panel {
      background : $bgMain;
      border     : none;
      padding    : 0;
      font-size  : 1.2em;
      font-weight: 500;
   }

   .noteForm {
      display       : flex;
      flex-direction: column;
      background    : $bgMain;
   }
}

.ant-collapse-header {
   color      : $primary !important;
   display    : flex;
   align-items: center;
   padding    : 5px 8px !important;
}

.ant-collapse-content {
   border-top: none !important;
}

.ant-collapse-content-box {
   padding: 0 10px 12px !important;
}

.ant-collapse-borderless {
   background-color: $bgBody !important;
}

.ant-collapse-borderless>.ant-collapse-item:last-child {
   border-radius: 5px !important;
}

.ant-tabs {
   overflow: unset !important;
}
